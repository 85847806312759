<template>
  <b-sidebar
    id="sidebar-create-edit"
    ref="sidebar-create-edit"
    bg-variant="white"
    right
    backdrop
    shadow
    :title="this.dataModules.id == null ? 'Cadastrar UAD' : 'Editar UAD'"
  >
  <div>
    <!-- Name -->
    <b-form-group
      label="* Nome:"
      label-for="i-nome"
      class="pl-1 pr-1"
    >
      <b-form-input
        id="i-nome"
        type="text"
        placeholder="Ex: TI"
        v-model="dataModules.nome"
      />
    </b-form-group>
    <b-form-group
      label="* UAD:"
      label-for="i-uad"
      class="pl-1 pr-1"
    >
        <b-form-select
            id="i-uad"
            v-model="dataModules.uad_id"
            :options="modules.uad"
            value-field="id"
            text-field="nome"
            class="form-control"
        />
    </b-form-group>
    <b-form-group
      label="* Tarefas Realizadas. (%):"
      label-for="i-nome"
      class="pl-1 pr-1"
    >
      <b-form-input
        id="i-nome"
        type="number"
        placeholder="Ex: 10"
        v-model="dataModules.porc_concluida"
      />
    </b-form-group>
    <b-form-group
      label="* Observações:"
      label-for="i-nome"
      class="pl-1 pr-1"
    >
      <b-form-input
        id="i-nome"
        type="text"
        placeholder="Ex: Realizar mais tarefas ..."
        v-model="dataModules.obs"
      />
    </b-form-group>

    <b-row class="ml-1 mr-1 pb-1">
      <b-button variant="relief-primary" block @click="storeAndUpdate">
        {{this.dataModules.id == null ? 'Salvar' : 'Editar'}}
      </b-button>
    </b-row>
    <b-row class="ml-1 mr-1 pb-1">
      <b-button variant="relief-danger" block @click="initValues">
        Limpar
      </b-button>
    </b-row>
  </div>
    
  </b-sidebar>
</template>
<script>
/* eslint-disable */
import { VBToggle } from 'bootstrap-vue'
import { mapActions, mapState } from "vuex";
import Ripple from "vue-ripple-directive";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
   props: { 
    dataProp: {
      type: Object,
      default: () => {}
    },
  },
  components: {
    mapActions,
    mapState
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple
  },
  data(){
    return {
      // Variáveis Global

        dataModules: {
            obs: null,
            campanha_id: null,
            porc_concluida: null,
            uad_id: null,
            nome: null,
            id: null,
            pathAPI: 'uad_acao'
        },
    }
  },
  computed: {
    ...mapState("connectAPI", ["modules"])
  },
  methods: {
    ...mapState("connectAPI", ["store", "update"]),

    initValues(){
        this.dataModules.id = null,
        this.dataModules.nome = null,
        this.dataModules.uad_id = null,
        this.dataModules.porc_concluida = null,
        this.dataModules.campanha_id = null,
        this.dataModules.obs = null
    },

    fetchModules(){
      this.isLoading = true
      this.$store
        .dispatch("connectAPI/fetchModules", {
          pathAPI: 'uad',
          paginated: false
        })
        .then((response) => {
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    storeAndUpdate() {
      if (this.dataModules.id == null) { 
        this.$store.dispatch("connectAPI/store", {
          data: this.dataModules
        })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Cadastrado com sucesso!',
              icon: 'ThumbsUpIcon',
              text: 'Operação executada com sucesso.',
              variant: 'success'
            }
          })
          this.initValues()
        })
        .finally(() => {
          this.$emit('fetch-all');
          this.closeSidebar()
        })
      } else {
        this.$store.dispatch("connectAPI/update", {
          data: this.dataModules
        })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Atualizado com sucesso!',
              icon: 'ThumbsUpIcon',
              text: 'Operação executada com sucesso.',
              variant: 'info'
            }
          })
          this.initValues();
        })
        .finally(() => {
          this.$refs['sidebar-create-edit'].hide()
          this.$emit('fetch-all');
        })
      }
    },

    closeSidebar(){
      this.$refs['sidebar-create-edit'].hide()
    }
  },
  watch: {
    dataProp: {
      immediate: true, 
      handler (val, oldVal) {
        if (!val) return; 
        if (this.dataProp.id == null) { 
          this.initValues(); 
        } else { 
            this.dataModules.id = this.dataProp.id;
            this.dataModules.nome = this.dataProp.nome;
            this.dataModules.uad_id = this.dataProp.uad_id;
            this.dataModules.porc_concluida = this.dataProp.porc_concluida;
            this.dataModules.campanha_id = this.dataProp.campanha_id;
            this.dataModules.obs = this.dataProp.obs;
                
        } 
      }
    }
  },
  created() {
      this.fetchModules();
  },
}
</script>